<template>
  <div class="container-fluid composer-layout">
    <div class="row h-100 flex-column">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComposerLayout',
}
</script>

<style>
.composer-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--pq-blue-shade2);
  overflow: hidden;
}
</style>
